<template>
  <div class="container">
    <div v-if="orderInfo.status !== 'unpay'" class="header mb10">
      <div class="header-hd">
        <div class="header-media">
          <van-icon name="description" />
        </div>
        <div class="header-inner">
          <div class="header-title">{{ orderInfo.statusStr }}</div>
        </div>
      </div>
      <van-steps :active="active">
        <van-step v-for="(v,i) in steps" :key="i">{{ v }}</van-step>
      </van-steps>
    </div>
    <div v-else class="header van-hairline--bottom">
      <div class="header-hd">
        <div class="header-media header-media-gray">
          <van-icon name="description" />
        </div>
        <div class="header-inner">
          <div class="header-title">{{ orderInfo.status_name }}</div>
        </div>
      </div>
      <van-steps :active="active">
        <van-step v-for="(v,i) in steps" :key="i">{{ v }}</van-step>
      </van-steps>
    </div>
    <!-- <div
        v-if="express.number"
        class="address-box-bd van-hairline--top"
      >物流信息：{{ express.expName }} {{ express.number }}</div> -->
    <div v-if="express.number" class="express mb10" @click="onNavigator({path:'/express?order_id='+$route.query.order_id})">
      <!-- <div class="express-hd">

      </div> -->
      <div class="express-bd">
        <div class="express-title">{{ express.list[0].status }}</div>
        <div class="express-txt">{{ express.list[0].time }}</div>
      </div>
      <div class="express-ft">
        <van-icon class="express-arrow" name="arrow" color="#999" size="20" />
      </div>
    </div>
    <div class="address-box mb10">
      <div class="address-box-hd">
        <div class="address-box-inner">
          <van-icon name="location-o" class="address-box-inner-icon" />
          <div class="address-box-inner-title">收货人：{{ address.user_name }}</div>
          <div class="address-box-inner-title">{{ address.tel_number }}</div>
        </div>
        <div class="address-box-inner-bottom">收货地址：{{ address.province }}{{ address.city }}{{ address.district }}{{ address.address }}</div>
      </div>
    </div>
    <van-panel class="panel" title="商品信息">
      <template slot="default">
        <van-card
          v-for="(good,index) in goods"
          :key="index"
          :num="good.product_count"
          :price="good.original_amount"
          :desc="good.product_spec"
          :title="good.product_name"
          :thumb="good.product_image"
        />
      </template>
      <div slot="footer" class="panel-actions">
        <van-button
          v-if="orderInfo.status === 'received'"
          plain
          round
          size="small"
          type="danger"
          @click="onShowPopup"
        >评价</van-button>
        <!-- <van-button
          v-if="orderInfo.status > 0 "
          plain
          round
          size="small"
          type="danger"
          @click="onRefundOrder"
        >退换</van-button> -->
      </div>
    </van-panel>
    <van-cell title="配送方式" value="快递发货" />
    <!-- <van-cell title="买家留言" :value="orderInfo.remark || '无'" /> -->
    <van-cell class="ui-right mb10">
      <template slot="title">
        合计：
        <span class="ui-c-red">￥{{ orderInfo.order_amount }}</span>
      </template>
    </van-cell>
    <div class="cell-group mb10">
      <!-- <div class="cell">
        <div class="cell-hd">商品金额</div>
        <div class="cell-bd">￥{{formatPrice(orderInfo.amount)}}</div>
      </div> -->
      <div class="cell">
        <div class="cell-hd">运费</div>
        <div class="cell-bd">{{ orderInfo.order_yunfei }} 元</div>
      </div>
      <!--   <div class="cell" v-if="!marketing.type">
        <div class="cell-hd">优惠</div>
        <div class="cell-bd">-{{formatPrice(extJson.discount || 0)}}</div>
      </div>
      <van-cell class="ui-right">
        <template slot="title">
          实付款：
          <span class="ui-c-red ui-font-bold">￥{{formatPrice(orderInfo.amountReal)}}</span>
        </template>
      </van-cell> -->
    </div>
    <div class="footer mb10">
      <div class="footer-p">
        订单编号：{{ orderInfo.serial_number }}
        <van-button
          plain
          type="default"
          size="mini"
          @click="handleClipboard(orderInfo.serial_number,$event)"
        >复制</van-button>
      </div>
      <div class="footer-p">创建时间：{{ orderInfo.order_time }}</div>
      <!-- <div class="footer-p" v-if="orderInfo.dateUpdate">更新时间：{{orderInfo.dateUpdate}}</div> -->
    </div>
    <!-- 订单评价 start -->
    <div v-if="comment.length" class="list">
      <van-cell title="评价" />
      <div v-for="item in comment" :key="item.comment_id" class="list-item mb10">
        <div class="list-item-header van-hairline--bottom">
          <div class="list-item-avatar"><van-image :src="item.commnet_avatar" alt="" /></div>
          <div class="list-item-inner">
            <div class="list-item-name">{{ item.commnet_name }}</div>
            <div class="list-item-star">
              <van-rate
                :value="item.commnet_rate | formatNumber"
                :size="14"
                :color="globalData.theme.color1"
                allow-half
                void-icon="star"
                void-color="#eee"
                readonly
              />
            </div>
          </div>
          <div class="list-item-date">{{ item.comment_at }}</div>
        </div>
        <div class="list-item-content"><p>{{ item.commnet_content }}</p></div>
        <div v-if="item.comment_image.length" class="list-item-images">
          <van-image
            v-for="(image,imageIndex) in item.comment_image"
            :key="imageIndex"
            class="list-item-image"
            :src="image"
            @click="onCommentPerview(imageIndex,item.comment_image)"
          />
        </div>
      </div>
    </div>
    <!-- 订单评价 end -->
    <div class="affix-bar">
      <div class="affix-bar__bar">
        <van-cell icon="service-o" value="联系客服" class="affix-bar__left" />
        <div v-if="orderInfo.status === 'unpay' || orderInfo.status === 'delivering'" class="affix-bar__inner">
          <van-button
            v-if="orderInfo.status === 'unpay'"
            class="ml5"
            plain
            round
            size="small"
            type="default"
            @click="onCancelOrder"
          >取消订单</van-button>
          <van-button
            v-if="orderInfo.status === 'unpay'"
            class="ml5"
            round
            size="small"
            type="danger"
            @click="paymentStore"
          >立即付款</van-button>
          <!-- <van-button
            v-if="orderInfo.status === 'delivering'"
            class="ml5"
            round
            size="small"
            type="danger"
            @click="onDeliveryOrder"
          >确认收货</van-button> -->
        </div>
      </div>
    </div>
    <!-- 评价弹层 -->
    <van-popup v-model="commentVisible" position="bottom">
      <div class="popup">
        <van-icon name="close" class="popup-close" @click="onShowPopup" />
        <div class="popup-body">
          <div class="popup-title">发表评价</div>
          <van-cell title="请选择您的评分" :border="false" />
          <van-field name="rate" allow-half label="评分">
            <template #input>
              <van-rate
                v-model="rate"
                allow-half
                void-icon="star"
                void-color="#eee"
                :color="globalData.theme.color1"
              />
            </template>
          </van-field>
          <van-field
            v-model="rateRemark"
            :border="false"
            label="备注"
            type="textarea"
            placeholder="非常愉快的一次购物！"
            rows="1"
            autosize
          />
          <van-field label="图片">
            <template #input>
              <van-uploader v-model="uploaImgs" max-count="3" :after-read="afterRead" :accept="uploadConfig.accept" :max-size="uploadConfig.maxSize" :preview-size="uploadConfig.previewSize" />
            </template>
          </van-field>
        </div>
        <div class="popup-bottom">
          <van-button class="popup-confirm" type="primary" size="large" @click="onSubmitRate">提交评价</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Tab, Tabs, Card, Panel, List, Step, Steps, Rate, Field, Uploader } from 'vant'
import { storage } from '@/common/util'
// import { pay_balance } from '@/common/pay'
import clipboard from '@/common/clipboard'
import PageMixin from '@/mixins/page'
import upload from '@/utils/upload'
import { wxPay } from '@/common/wx'
import { ImagePreview } from 'vant'

export default {
  name: 'OrderDetail',
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Card.name]: Card,
    [Panel.name]: Panel,
    [List.name]: List,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Rate.name]: Rate,
    [Field.name]: Field,
    [Uploader.name]: Uploader
  },
  mixins: [PageMixin],
  data() {
    return {
      extJson: {}, // 扩展对象
      active: -1,
      steps: ['买家付款', '商家发货', '交易完成'],
      orderInfo: {},
      goods: [],
      address: {},
      express: {},
      comment: [],
      hasSteps: false,
      commentVisible: false,
      rate: 5,
      uploaImgs: [],
      // reputation:2,   // 0 差评 1 中评 2 好评
      rateRemark: '', // 评价备注，限200字符
      closeTime: 0,
      uploadConfig: {
        accept: 'image/png,image/jpeg',
        maxSize: 4 * 1024 * 1024,
        previewSize: '60px'
      }
    }
  },
  created() {
    this.onDetail()
    this.onExperss()
    this.onComment()
  },
  methods: {
    onDetail() {
      this.$api.order_detail({ order_id: this.$route.query.order_id })
        .then(res => {
          this.orderInfo = res.data
          this.goods = res.data.orders
          this.address = res.data.address
          this.orderInfo.status === 'unpay' && (this.active = -1)
          this.orderInfo.status === 'closed' && (this.active = -1)
          this.orderInfo.status === 'payed' && (this.active = 0)
          this.orderInfo.status === 'delivering' && (this.active = 1)
          this.orderInfo.status === 'received' && (this.active = 2)
        })
    },
    onExperss() {
      this.$api.order_express({ order_id: this.$route.query.order_id }).then(res => {
        this.express = res.data || {}
      })
    },
    onComment() {
      this.$api.order_comment({ order_id: this.$route.query.order_id }).then(res => {
        this.comment = res.data.comments
      })
    },
    formatPrice(price) {
      return parseFloat(price).toFixed(2)
    },
    onShowPopup() {
      this.commentVisible = !this.commentVisible
    },
    onRefundOrder() {
      //
      this.$router.push({
        path: 'refund-apply',
        query: { orderId: this.orderInfo.id, amount: this.orderInfo.amount }
      })
    },
    onDeliveryOrder() {
      this.$dialog
        .confirm({
          title: '提示',
          message: '确认您已收到商品？'
        })
        .then(() => {
          // on confirm
          this.$toast.loading({
            mask: true,
            message: '加载中...',
            duration: 0
          })
          this.$request
            .post('/order/delivery', {
              orderId: this.orderInfo.id,
              token: storage.get('token')
            })
            .then(res => {
              if (res.code !== 0) {
                this.$toast(res.msg)
                return
              }
              this.$router.go(-1)
              this.$toast({ message: '收货成功!', duration: 1500 })
            })
        })
        .catch(() => {
          // on cancel
        })
    },
    onCancelOrder() {
      const orderId = this.orderInfo.order_id
      this.$dialog
        .confirm({
          // title: '提示',
          message: '订单还未付款,确定要取消吗?',
          cancelButtonText: '在考虑下',
          confirmButtonText: '取消订单'
        })
        .then(() => {
          // on confirm
          this.$toast.loading({
            mask: true,
            message: '加载中...',
            duration: 0
          })
          this.$api.order_cancelorder({ order_id: orderId })
            .then(res => {
              this.$toast({ message: '取消订单成功', duration: 1500 })
              setTimeout(() => {
                this.$router.back()
              }, 1500)
            })
            .catch(err => {
              this.$toast({ message: '取消订单失败', duration: 1500 })
              console.error(err)
            })
        })
        .catch(() => {
          // on cancel
        })
    },
    paymentStore() {
      const orderId = this.orderInfo.order_id
      // const amountReal = this.orderInfo.amountReal
      // couponId && (params.coupon_id = couponId)
      this.$toast.loading({
        mask: true,
        message: '支付中...',
        duration: 0
      })
      const params = {
        order_id: orderId
      }
      this.orderInfo.coupon_id && (params.coupon_id = this.orderInfo.coupon_id)
      this.$api.payment_store(params)
        .then(res => {
          const { appId, timeStamp, nonceStr, package: packageStr, signType, paySign } = res.data.jsApiParameters
          wxPay( appId, timeStamp, nonceStr, packageStr, signType, paySign)
            .then(res => {
              this.$toast.success('支付成功')
              this.$router.replace({
                path: '/order-detail',
                query: { order_id: orderId }
              })
            })
            .catch(err => {
              this.$toast.fail('支付失败')
              setTimeout(() => {
                this.redirectPage('fail')
              }, 1500)
              console.error(err)
            })
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$toast.clear()
        })
    },
    // 评论
    onSubmitRate() {
      const params = {
        comment_entity: this.orderInfo.comment_entity,
        comment_rate: this.rate,
        comment_content: this.rateRemark || '非常愉快的一次购物！',
        comment_image: this.uploaImgs.map(item => item.url)
      }

      this.$toast.loading({
        mask: true,
        message: '提交中...',
        duration: 0
      })
      this.$api.order_addcomment(params)
        .then(res => {
          this.$toast.success('评价成功!')
          this.onShowPopup()
          setTimeout(() => {
            this.$router.back()
          }, 1500)
        })
        .catch(err => {
          this.$toast.fail('评价失败!')
          console.error(err)
        })
    },
    afterRead(file, detail) {
      file.status = 'uploading'
      file.message = '上传中...'
      upload({ file: file.file })
        .then(res => {
          this.uploaImgs.splice(detail.index, 1, { url: res.path, status: 'done', message: '上传成功' })
        })
        .catch(err => {
          console.error(err)
          file.status = 'failed'
          file.message = '上传失败'
        })
    },
    handleClipboard(text, event) {
      clipboard(text, event)
    },
    onCommentPerview(index, images) {
      WeixinJSBridge.invoke("imagePreview", {
          "urls":images,
          "current": images[index]
      })
    }
  }
}
</script>

<style lang="less" scoped>
.color-red {
  color: #f44 !important;
}
.container {
  padding-bottom: 100px;
}
//
.header {
  min-height: 80px;
  padding: 0 5px;
  background: #fff;
  &-title {
    font-size: 14px;
    color: #333;
  }
  &-txt {
    font-size: 12px;
    color: #979797;
  }
  &-hd {
    display: flex;
    align-items: center;
    padding: 10px 10px 0;
  }
  &-media {
    display: inline-block;
    padding: 5px 5px;
    background: #00c062;
    border-radius: 4px;
    margin-right: 10px;
    /deep/ .van-icon {
      font-size: 32px;
      vertical-align: top;
      color: #fff;
    }
  }
  &-media-gray {
    background: #c9c9c9;
  }
}
//
.address-box {
  position: relative;
  box-sizing: border-box;
  min-height: 60px;
  // display: flex;
  // justify-content: flex-start;
  // align-items: center;
  padding: 8px 15px;
  background: #fff;
  &-hd {
    flex: 1;
    // padding:0 10px;
    padding-left: 20px;
  }
  &-bd {
    margin-top: 10px;
    padding: 10px 15px 5px;
    font-size: 14px;
    color: #333;
  }
  &-inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-icon {
      position: absolute;
      top: 2px;
      left: -20px;
    }
    &-title {
      font-size: 14px;
      color: #333;
      font-weight: bold;
      margin-bottom: 5px;
    }
    &-bottom {
      font-size: 12px;
      color: #999;
    }
  }
  &:before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 2px;
    background: -webkit-repeating-linear-gradient(
      135deg,
      #ff6c6c 0,
      #ff6c6c 20%,
      transparent 0,
      transparent 25%,
      #00de93 0,
      #00de93 45%,
      transparent 0,
      transparent 50%
    );
    background: repeating-linear-gradient(
      -45deg,
      #ff6c6c 0,
      #ff6c6c 20%,
      transparent 0,
      transparent 25%,
      #00de93 0,
      #00de93 45%,
      transparent 0,
      transparent 50%
    );
    background-size: 80px;
    content: "";
  }
}
.address-card {
  position: relative;
  padding: 10px 15px;
  align-items: center;
  &:before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 2px;
    background: -webkit-repeating-linear-gradient(
      135deg,
      #ff6c6c 0,
      #ff6c6c 20%,
      transparent 0,
      transparent 25%,
      #00de93 0,
      #00de93 45%,
      transparent 0,
      transparent 50%
    );
    background: repeating-linear-gradient(
      -45deg,
      #ff6c6c 0,
      #ff6c6c 20%,
      transparent 0,
      transparent 25%,
      #00de93 0,
      #00de93 45%,
      transparent 0,
      transparent 50%
    );
    background-size: 80px;
    content: "";
  }
}
.address-card .van-cell__left-icon {
  color: #00de93;
  font-size: 40px;
}
.address-card .van-cell__title {
  line-height: 40px;
}
//
.cell-group {
  box-sizing: border-box;
  padding: 10px 0;
  background: #fff;
}
.cell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  color: #555;
  line-height: 20px;
  padding-left: 15px;
  padding-right: 15px;
  background: #fff;
  &-hd {
  }
  &-bd {
    flex: 1;
    text-align: right;
  }
}
//
.footer {
  padding: 20px 15px;
  background: #fff;
}
.footer-p {
  font-size: 12px;
  line-height: 20px;
  color: #999;
}
.card-button {
  display: inline-block;
  margin-right: 10px;
  font-size: 12px;
  padding: 4px 12px;
  border-radius: 20px;
  color: #666;
  border: 1px solid #b9b9b9;
}
.card-button-danger {
  color: #f44;
  border: 1px solid #f44;
}
.card-button:active {
  opacity: 0.8;
}
//
.popup {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 300px;
  &-close {
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 20;
    color: #969799;
    font-size: 20px;
    text-align: center;
  }
  &-body {
    width: 100%;
    flex: 1;
  }
  &-title {
    font-size: 16px;
    text-align: center;
    padding: 10px 0;
  }
  &-bottom {
    width: 100%;
  }
  .rate-box {
    padding: 0px 15px;
    margin-bottom: 30px;
  }
}
.panel-actions {
  text-align: right;
  .van-button {
    margin-left: 10px;
  }
}
.affix-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background-color: #fff;
  user-select: none;
  &__bar {
    // padding:0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 14px;
  }
  &__button {
    // width: 100%;
    // height: 50px;
    // line-height: 48px;
  }
  &__left {
    max-width: 110px;
  }
  &__inner {
    padding-right: 15px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.express{
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 15px;
  background: #fff;
  &-bd{
    flex: 1;
    margin-right: 10px;
  }
  &-ft{

  }
  &-title{
    font-size: 12px;
    color: #333;
    margin-bottom: 10px;
  }
  &-txt{
    font-size: 10px;
    color: #999;
  }
}

.list-item{
  background:#fff;
  &-avatar{
    width:40px;
    height:40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right:5px;
  }
  &-name{
    font-size: 14px;
    color:#555;
    line-height: 1;
  }
  &-inner{
    flex:1;
    padding-top:4px;
  }
  &-date{
    font-size: 12px;
    color:#999;
  }
  &-header{
    padding:10px 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &-content{
    padding:10px 15px;
    color:#999;
    font-size: 14px;
  }
  &-images {
    padding:10px 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &-image {
    width: 80px;
    height: 80px;
    margin-right: 10px;
  }
}
</style>

